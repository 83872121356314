<template>
  <div class="page">
    <Navbar title="推广订单" type="SMP" />
    <van-row class="count">
      <van-col span="8" class="bdc-r1s-gray"
        ><div class="amount color-m">{{ count.wait }}次</div>
        <div class="text">未处理数量</div></van-col
      >
      <van-col span="8" class="bdc-r1s-gray"
        ><div class="amount color-s1">{{ count.pass }}个</div>
        <div class="text">已录用数量</div></van-col
      >
      <van-col span="8"
        ><div class="amount color-s2">{{ count.pause }}个</div>
        <div class="text">未录用数量</div></van-col
      >
    </van-row>
    <van-tabs
      :active="resultTab"
      border="true"
      :color="COLOR"
      @change="tabChange"
    >
      <van-tab title="未处理" name="WAT"> </van-tab>
      <van-tab title="已录用" name="PAS"> </van-tab>
      <van-tab title="未录用" name="PAU"> </van-tab>
    </van-tabs>
    <div class="sign bdc-b1s-gray" v-for="sign in signList" :key="sign.code">
      <van-row>
        <van-col span="24" class="name text-short"
          ><van-icon name="user-o" /> 姓名：{{ sign.name }}
        </van-col>
      </van-row>
      <van-row>
        <van-col span="24" class="time"
          ><van-icon name="underway-o" /> 报名时间：{{
            sign.createTime
          }}</van-col
        >
      </van-row>
      <van-row class="btn">
        <van-col span="24"
          ><van-button
            :color="COLOR_S1"
            icon="sign"
            size="mini"
            v-if="resultTab === 'WAT' || resultTab === 'PAU'"
            @click="updateResult('PAS')"
            >录用提交</van-button
          >&nbsp;
          <van-button
            :color="COLOR_M"
            icon="clock-o"
            size="mini"
            v-if="resultTab === 'WAT' || resultTab === 'PAS'"
            @click="updateResult('PAU')"
            >暂存挂起</van-button
          >&nbsp;
          <van-button
            :color="COLOR_S2"
            icon="phone-o"
            size="mini"
            @click="cellphone(sign.phone)"
            >拨打电话</van-button
          >
        </van-col></van-row
      >
    </div>
    <van-row class="no-record" v-if="signList.length <= 0">
      <van-col span="24">
        <van-image
          width="103"
          height="103"
          :src="require('../../../../assets/images/home/no-record.png')"
        ></van-image
      ></van-col>
    </van-row>
  </div>
</template>
<script>
import Index from './Index.js'
export default {
  ...Index
}
</script>
<style lang="less" scoped>
@import './Index.less';
</style>
